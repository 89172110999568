import React from 'react'
import ContentLoader from 'react-content-loader'
const windowWidth = window.innerWidth

export const LoaderNavBar = () => (
    <ContentLoader
        height={windowWidth < 769 ? 100 : 21}
        width={windowWidth < 769 ? 500 : 400}
        speed={2}
        primaryColor="#f3f3f3"
    >
        <rect
            x="3%"
            y="25%"
            rx="3"
            ry="3"
            width={window.innerWidth < 769 ? '80' : '32'}
            height={window.innerWidth < 769 ? '50' : '10'}
        />
    </ContentLoader>
)

export const LoaderAllopenings = () => (
    <ContentLoader height={120} width={400} speed={2} primaryColor="#f3f3f3">
        <rect x="35%" y="5" rx="3" ry="3" width="30%" height="10" />
        <rect x="25%" y="30" rx="3" ry="3" width="50%" height="20" />
        <rect x="25%" y="60" rx="3" ry="3" width="50%" height="20" />
        <rect x="25%" y="90" rx="3" ry="3" width="50%" height="20" />
    </ContentLoader>
)

export const LoaderBanner = () => (
    <ContentLoader height={windowWidth < 769 ? 300 : 135} width={400} speed={2}>
        <rect x="0" y="0" rx="0" ry="0" width="100%" height={windowWidth < 769 ? '300' : '135'} />
    </ContentLoader>
)
