import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <g fill="#4767A0">
                <path d="M10.873 10.346a.525.525 0 01-.527.527H1.654a.525.525 0 01-.527-.527V1.654c0-.293.234-.527.527-.527h3.424V.073H1.654c-.878 0-1.58.703-1.58 1.58v8.693c0 .878.702 1.58 1.58 1.58h8.692c.878 0 1.58-.702 1.58-1.58V6.922h-1.053v3.424z"></path>
                <path d="M6.922.073v1.054h3.205L5.985 5.268l.747.747 4.141-4.142v3.205h1.054V.073z"></path>
            </g>
        </svg>
    )
}

export default Icon
