import config from './../config'
import axios from 'axios'
const { apiURL } = config.config()

export const getPageData = async domain => {
    try {
        const url = apiURL + domain
        let res = await axios(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            responseType: 'json',
        })

        return res.data
    } catch (error) {
        Promise.reject(error)
    }
}

export const getOpeningsByWidgetToken = async ({ token, companyId }) => {
    try {
        const url = `${apiURL}widget/${companyId}/openings`

        let res = await axios(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            responseType: 'json',
        })

        return { body: res.data }
    } catch (error) {
        const { response } = error
        if (response && response.data) {
            console.log(response.data.error.msg)
        }
        return Promise.reject(error)
    }
}
