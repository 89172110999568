import React from 'react'
import { BENEFIT_DATA } from './../DummyData'
import { Body, Num, Description } from './style'
import { Heading, Row, Cell, Desc, SingleCell } from '../main-styles'

export default function Benefits({ data = {} }) {
    if (data === null || !data.hasOwnProperty('details') || data.details === null || data.details.length === 0)
        return null

    const BenefitRender = data.details.map((each, index) => (
        <Cell key={index}>
            <Num>{each.title}</Num>
            <Desc>{each.description}</Desc>
        </Cell>
    ))

    return (
        <Body>
            <Heading>{data.title || BENEFIT_DATA.title}</Heading>
            {data && data.details.length === 1 ? (
                <SingleCell>
                    <Num>{data.details[0].title}</Num>
                    <Description>{data.details[0].description}</Description>
                </SingleCell>
            ) : (
                <Row cells={data.details.length} benefits>
                    {BenefitRender}
                </Row>
            )}
        </Body>
    )
}
