import React from 'react'
import WorkWithUs from './WorkWithUs/WorkWithUs'
import Culture from './Culture/Culture'
import Section from './Section'
import Benefits from './Benefits/Benefits'

export const DynamicSection = ({ data: { benefits, work, culture, sections, hasSettings } }) => {
    // Note: Dynamic section do not have loaders, as positions are unknown
    if (hasSettings === null) return null
    let renderArray = []

    // looping the know types
    if (work && work.position >= 0) renderArray[work.position] = <WorkWithUs key={work.position} data={work} />

    if (culture && culture.position >= 0)
        renderArray[culture.position] = <Culture key={culture.position} data={culture} />

    if (benefits && benefits.position >= 0)
        renderArray[benefits.position] = <Benefits key={benefits.position} data={benefits} />

    // looping sections
    if (sections)
        sections.forEach(each => {
            renderArray[each.position] = <Section key={each.position} data={each} />
        })

    return renderArray
}

export default DynamicSection
