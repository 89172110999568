import React, { useState, useEffect } from 'react'
import { Heading } from '../main-styles'
import { Body, Empty } from './style'

const OpeningsPreview = props => {
    return (
        <Body id="all-openings">
            <Heading>All Openings</Heading>
            <Empty>List of open positions will be shown here.</Empty>
        </Body>
    )
}

export default OpeningsPreview
