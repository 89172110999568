import React from 'react'
import { Body, Num } from './style'
import { Heading, Row, Cell, Desc, SingleCell } from '../main-styles'
import { WORK_DATA } from './../DummyData'

export default function WorkWithUs({ data }) {
    if (data === null || !data.hasOwnProperty('points') || (data.points && data.points.length === 0)) return null

    const worksRender = data.points.map((desc, index) => (
        <Cell key={index}>
            <Num>{`0${index + 1}`}</Num>
            <Desc>{desc}</Desc>
        </Cell>
    ))

    return (
        <Body>
            <Heading>{data.title || WORK_DATA.title}</Heading>
            {data && data.points.length === 1 ? (
                <SingleCell>{data.points[0]}</SingleCell>
            ) : (
                <Row cells={data.points.length}>
                    {worksRender}
                    {data.points.length === 5 && <Cell key="empty-cell-key"></Cell>}
                </Row>
            )}
        </Body>
    )
}
