import React from 'react'
import { Navbar, Logo, CompanyName, CompanyWebsiteBtn, LogoWrapper } from './style'
import { LoaderNavBar } from './../../Loaders/index'
import OpenNewTab from './ImageComponents/OpenNewLink'

export default function Nav({ data, isLoading }) {
    if (isLoading) return <LoaderNavBar />
    return (
        <Navbar>
            {data.logo_url && data.logo_url.length ? (
                <Logo src={data.logo_url} />
            ) : (
                <CompanyName>{data.companyName || ''}</CompanyName>
            )}
            {
                //Blockchain.com is a dummy company setup to show job openings for all blockchain companies.
                //So there will be no company website
            }
            {data.companyUrl && data.companyUrl !== 'blockchain.com' && (
                <CompanyWebsiteBtn onClick={() => window.open(`http://${data.companyUrl}`, '_blank')}>
                    Company website
                    <OpenNewTab />
                </CompanyWebsiteBtn>
            )}
        </Navbar>
    )
}
