import styled from 'styled-components'

export const Body = styled.section`
    position: relative;
    background-color: rgb(255, 255, 255);
`
export const BannerImg = styled.div`
    width: 100%;
    padding-bottom: 33%;
    background: linear-gradient(to top, rgba(0,0,0,0.85),transparent),url("${props => props.src}");
    background-repeat: no-repeat;
    background-size:cover;
    @media (max-width: 767px) {
        height: 300px;
    }
`
export const Content = styled.div`
    position: absolute;
    left: 8%;
    bottom: 20%;
    @media (max-width: 767px) {
        left: 20px;
        right: 20px;
    }
`
export const Heading = styled.span`
    display: block;
    max-width: 612px;
    font-family: SFProBold;
    font-size: 36px;
    color: #fff;
    margin-bottom: 15px;
`
export const Desc = styled.span`
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
    display: block;
`
export const Button = styled.button`
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    font-family: SFProSemibold;
    letter-spacing: 0.8px;
    color: #4767a0;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
`
