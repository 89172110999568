import styled from 'styled-components'

export const Body = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 30px;
`
export const Num = styled.span`
    font-family: SFProHeavy;
    font-size: 80px;
    color: #ddd;
`
