import styled from 'styled-components'

export const Body = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 80px;

    .ant-carousel {
        width: 100%;
        padding: 0 80px;
    }
    .ant-carousel .slick-slide {
        margin-top: 50px;
        text-align: center;
        max-height: 400px;
        background: #fff0;
        overflow: hidden;
    }
    @media (max-width: 767px) {
        .ant-carousel {
            padding: 0 40px;
        }
    }
`
export const BannerImg = styled.img.attrs({ src: props => props.src })`
    width: 100%;
`
