import React from 'react'
import { Body, BannerImg, Content, Heading, Desc, Button } from './style'
import { LoaderBanner } from './../../Loaders/index'
import { BANNER_DATA } from '../DummyData'

export default function Banner({ data = null, isLoading }) {
    if (isLoading) return <LoaderBanner />

    if (data === null) data = BANNER_DATA

    return (
        <Body>
            <BannerImg src={data.background_url || BANNER_DATA.background_url} />
            <Content>
                <Heading>{data.title || BANNER_DATA.title}</Heading>
                <Desc>{data.description || BANNER_DATA.description}</Desc>
                <a href="#all-openings">
                    <Button>VIEW OPENINGS</Button>
                </a>
            </Content>
        </Body>
    )
}
