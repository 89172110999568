import React from 'react'
import { Body, BannerImg } from './style'
import { Heading, Description } from '../main-styles'
import { Carousel } from 'antd'

export default function Culture({ data = null }) {
    if (
        !data ||
        !(data.title && data.title.length) ||
        !((data.image_urls && data.image_urls.length) || (data.description && data.description.length))
    )
        return null

    const cultureRender =
        data.image_urls &&
        data.image_urls.map((each, index) => (
            <div key={index}>
                <BannerImg src={each} />
            </div>
        ))
    return (
        <Body>
            <Heading>{data.title}</Heading>
            <Description>{data.description}</Description>
            {cultureRender && cultureRender.length ? <Carousel autoplay>{cultureRender}</Carousel> : null}
        </Body>
    )
}
