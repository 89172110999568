import styled from 'styled-components'

export const Heading = styled.span`
    margin-bottom: 25px;
    font-family: SFProBold;
    word-break: break-word;
    font-size: 40px;
    font-weight: bold;
    color: #333;
    align-self: center;
    padding: 0 20px;
    text-align: center;
`
export const Description = styled.span`
    max-width: 748px;
    word-break: break-word;
    font-size: 14px;
    color: #666;
    text-align: center;
    align-self: center;

    @media (max-width: 767px) {
        width: 80%;
    }
`
export const Row = styled.div`
    display: flex;
    justify-content: ${props => (props.cells === 2 ? 'space-evenly' : 'space-between')};
    padding: ${props => (props.cells === 4 || props.cells === 2 ? '0 10%' : '0 50px')};
    flex-wrap: wrap;
    position: relative;

    > div {
        width: ${props => (props.cells === 4 ? '50%' : '33%')};
    }
    ${props =>
        props.cells === 4 &&
        props.benefits &&
        `
        :before {
            content: '';
            position: absolute;
            border-top: 1px #d4d4d4 solid;
            width: 73%;
            top: 50%;
            left: 13%;
        }
        :after {
            content: '';
            position: absolute;
            border-right: 1px #d4d4d4 solid;
            height: 82%;
            left: 50%;
            top: 10%;
        }

        @media (max-width: 767px) {
            :before, : after {
                display: none;
            }
        }
    `}
    @media (max-width: 767px) {
        padding: 0;
        > div {
            width: 100%;
            align-items: center;
        }
    }
`
export const SingleCell = styled.div`
    max-width: 748px;
    word-break: break-word;
    align-self: center;
    font-size: 14px;
    text-align: center;
    color: #666;

    @media (max-width: 767px) {
        width: 80%;
    }
`
export const Cell = styled.div`
    display: flex;
    flex-direction: column;
    width: 33%;
    padding: 40px 50px;
    @media (max-width: 767px) {
        padding: 20px 40px;
    }
`
export const Desc = styled.span`
    font-size: 14px;
    word-break: break-word;
    white-space: pre-wrap;
    color: #666;

    @media (max-width: 767px) {
        text-align: center;
    }
`
