import styled from 'styled-components'

export const Navbar = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background: #fff;
    justify-content: space-between;
`
export const Logo = styled.img.attrs({ src: props => props.src })`
    margin-left: 80px;
    height: 50%;
    @media (max-width: 767px) {
        margin-left: 20px;
    }
`
export const CompanyName = styled.div`
    font-family: SFProBold;
    font-size: 26px;
    font-weight: 900;
    color: #333;
    text-transform: uppercase;
    margin-left: 80px;
    @media (max-width: 767px) {
        margin-left: 20px;
    }
`
export const CompanyWebsiteBtn = styled.button`
    width: 140px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #4767a0;
    font-size: 12px;
    background: #fff;
    color: #4767a0;
    margin-right: 80px;
    cursor: pointer;
    font-family: SFProSemibold;

    svg {
        margin-left: 5px;
        vertical-align: middle;
    }
    @media (max-width: 767px) {
        margin-right: 20px;
    }
`
