import styled from 'styled-components'

export const Body = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    background-color: #f7f8fc;
    overflow: hidden;
`
export const CTAButtons = styled.div`
    display: inline-block;
`
export const Button = styled.button`
    width: 80px;
    height: 42px;
    background: #4767a0;
    font-size: 12px;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-right: 11.35px;
    cursor: pointer;
    visibility: ${({ search, location }) => (search === '' && location === 'all' ? 'hidden' : 'visible')};

    :hover {
        opacity: 0.8;
    }
    :last-child {
        margin-right: 0px;
    }
`
export const List = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (max-width: 767px) {
        width: 90%;
    }
`
export const Item = styled.div`
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 70px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    margin: 10px 0;
    flex-wrap: wrap;
`
export const Role = styled.div`
    flex: 3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
    a {
        font-size: 16px;
        color: #4767a0;
        font-family: SFProBold;
    }
    @media (max-width: 767px) {
        width: 100%;
        flex: auto;
        padding-right: 0;
    }
`
export const Label = styled.span`
    font-family: SFProMedium;
    font-size: 12px;
    color: #999;
    display: flex;
    align-items: center;
    margin: 10px;
    max-width: 120px;
    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    svg {
        flex-shrink: 0;
        margin-right: 5px;
    }
    @media (max-width: 767px) {
        flex: auto;
        width: 46%;
        margin-right: 4%;
    }
`
export const Form = styled.form`
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
`
const inputStyles = `
    @media (max-width: 767px) {
        margin-bottom: 15px;
        margin-right: 0;
        width: 100%;
    }
`
export const Select = styled.select`
    width: 256px;
    height: 42px;
    margin-right: 11.35px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    border: none;
    background: #fff;
    font-size: 12px;
    color: #999;
    padding-left: 10px;
    -webkit-appearance: none;
    cursor: pointer;

    ${inputStyles}
`
export const Input = styled.input`
    width: 256px;
    height: 42px;
    margin-right: 11.35px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    border: none;
    padding-left: 10px;
    ::placeholder {
        font-size: 12px;
        color: #999;
    }

    ${inputStyles}
`
export const Empty = styled.div`
    margin-top: 50px;
    text-align: center;
    width: 710px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    padding: 40px 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px) {
        width: ${props => (props.small ? '90%' : '100%')};
    }
`

export const Job = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    flex-wrap: wrap;
    border-top: 1px solid #ddd;
`

export const Labels = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
