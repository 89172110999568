import React, { Component } from 'react'
import { Job, Label, Role, Labels } from '../style'
import { ReactComponent as BriefcaseIcon } from '../../../../assets/img/briefcase.svg'
import { ReactComponent as LocationIcon } from '../../../../assets/img/location.svg'
import { ReactComponent as CompanyIcon } from '../../../../assets/img/Company.svg'
import { Content, Header, Item, Arrow } from './style'
import config from '../../../../config'

const { env } = config.config()

export default class Accordion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: true,
        }
    }

    swapOpenState = () => {
        this.setState(state => ({ isOpen: !state.isOpen }))
    }

    applyNowLinkGenerator = id => {
        return `https://${env === 'production' ? '' : 'dev.'}springrecruit.com/applyNow/${id}`
    }

    allJobs = ({
        _id,
        jobTitle,
        jobLocation,
        minExperience,
        maxExperience,
        uniqueReferal,
        agencyStatus,
        companyName,
    }) => {
        return (
            <Job key={_id}>
                <Role>
                    <a href={this.applyNowLinkGenerator(uniqueReferal)} target="_blank">
                        {jobTitle}
                    </a>
                </Role>
                <Labels>
                    {agencyStatus === 1 && (
                        <Label>
                            <CompanyIcon /> <span>{companyName}</span>
                        </Label>
                    )}
                    {minExperience !== null && maxExperience !== null ? (
                        minExperience === 0 && maxExperience === 0 ? (
                            <Label>
                                <BriefcaseIcon /> Fresher
                            </Label>
                        ) : (
                            <Label>
                                <BriefcaseIcon />{' '}
                                <span>
                                    {minExperience} - {maxExperience} Years
                                </span>
                            </Label>
                        )
                    ) : null}
                    <Label>
                        {jobLocation ? (
                            <>
                                <LocationIcon /> <span>{jobLocation}</span>
                            </>
                        ) : null}
                    </Label>
                </Labels>
            </Job>
        )
    }

    render() {
        const { isOpen } = this.state
        const { companyName, data, companyId } = this.props
        const listRender = data.filter(each => each.companyId === companyId).map((each, index) => this.allJobs(each))

        if (listRender.length === 0) return null

        return (
            <Item>
                <Header onClick={this.swapOpenState}>
                    <span>{companyName}</span>
                    <Arrow isOpen={isOpen} />
                </Header>
                <Content isOpen={isOpen} total={listRender.length}>
                    {listRender}
                </Content>
            </Item>
        )
    }
}
