import React, { Fragment, Component } from 'react'
import { Body, CTAButtons, Button, List, Item, Role, Label, Form, Select, Input, Empty, Labels } from './style'
import { Heading } from '../main-styles'
import { ReactComponent as LocationIcon } from '../../../assets/img/location.svg'
import { ReactComponent as BriefcaseIcon } from '../../../assets/img/briefcase.svg'
import { ReactComponent as CompanyIcon } from '../../../assets/img/Company.svg'
import config from '../../../config'
import { LoaderAllopenings } from './../../Loaders/index'

// here the props will never change.
export default class Openings extends Component {
    constructor(props) {
        super(props)
        let locationObj = {}
        const { data } = props
        const hasOpening = (data && data.length > 0) || false
        const openingsRender =
            hasOpening &&
            data.map((opening, index) => {
                if (opening.jobLocation && opening.jobLocation.length > 0) {
                    const { jobLocation } = opening
                    // this DataStructure will make title search for given location very quick
                    if (locationObj.hasOwnProperty(jobLocation)) locationObj[jobLocation].push(opening)
                    else locationObj[jobLocation] = [opening]
                }
                return this.optionListRender(opening)
            })

        const locationList = Object.keys(locationObj)

        // the location can be null for all the jobs
        const locationOptions =
            (locationList.length &&
                locationList.map(each => (
                    <option value={each} key={each}>
                        {each}
                    </option>
                ))) ||
            []
        if (locationList.length)
            locationOptions.unshift(
                <option value="all" key="all">
                    All Locations
                </option>
            )

        this.state = {
            search: '',
            location: 'all',
            hasOpening,
            locationOptions,
            openingsRender,
            locationObj,
            clearButtonVisible: false,
        }
    }

    searchChanged = ({ target }) => {
        // first entered text cannot be <space>
        this.setState({
            search: target.value.length === 1 ? target.value.trim() : target.value,
        })
    }

    locationChanged = ({ target }) => {
        this.setState({
            location: target.value,
        })
    }

    applyNowLinkGenerator = id => {
        return `https://${config.config().env === 'production' ? '' : 'dev.'}springrecruit.com/applyNow/${id}`
    }

    optionListRender = ({
        _id,
        jobTitle,
        jobLocation,
        minExperience,
        maxExperience,
        uniqueReferal,
        agencyStatus,
        companyName,
    }) => {
        return (
            <Item key={_id}>
                <Role>
                    <a href={this.applyNowLinkGenerator(uniqueReferal)} target="_blank" title={jobTitle}>
                        {jobTitle}
                    </a>
                </Role>
                <Labels>
                    {agencyStatus === 1 && (
                        <Label title={companyName}>
                            <CompanyIcon /> <span>{companyName}</span>
                        </Label>
                    )}
                    {minExperience !== null && maxExperience !== null ? (
                        minExperience === 0 && maxExperience === 0 ? (
                            <Label>
                                <BriefcaseIcon /> Fresher
                            </Label>
                        ) : (
                            <Label title={`${minExperience} - ${maxExperience} Years`}>
                                <BriefcaseIcon />{' '}
                                <span>
                                    {minExperience} - {maxExperience} Years
                                </span>
                            </Label>
                        )
                    ) : null}
                    <Label title={jobLocation}>
                        <LocationIcon /> <span>{jobLocation}</span>
                    </Label>
                </Labels>
            </Item>
        )
    }

    go = e => {
        e.preventDefault()
        let { location, locationObj, search } = this.state
        let ls = []
        search = search.trim()

        if (location === 'all' && search.length > 0) {
            this.props.data.forEach(opening => {
                if (opening.jobTitle.toLowerCase().indexOf(search.toLowerCase()) > -1)
                    ls.push(this.optionListRender(opening))
            })
        } else if (location === 'all') {
            ls = this.props.data.map(opening => this.optionListRender(opening))
        } else if (search.length > 0) {
            locationObj[location].forEach(opening => {
                if (opening.jobTitle.toLowerCase().indexOf(search.toLowerCase()) > -1)
                    ls.push(this.optionListRender(opening))
            })
        } else locationObj[location].forEach(opening => ls.push(this.optionListRender(opening)))

        this.setState({
            openingsRender: ls,
            clearButtonVisible: true,
        })
    }

    clearSearch = e => {
        e.preventDefault()
        let openingsRender = this.props.data.map(opening => this.optionListRender(opening))

        this.setState({
            openingsRender,
            location: 'all',
            search: '',
            clearButtonVisible: false,
        })
    }

    render() {
        const { search, location, hasOpening, locationOptions, openingsRender, clearButtonVisible } = this.state
        const { isLoading } = this.props
        return (
            <Body id="all-openings">
                <Heading>All Openings</Heading>
                {isLoading ? (
                    <LoaderAllopenings />
                ) : hasOpening ? (
                    <Fragment>
                        <Form>
                            <Input
                                placeholder="Search Job"
                                name="search"
                                value={search}
                                onChange={this.searchChanged}
                            />
                            {locationOptions.length ? (
                                <Select value={location} onChange={this.locationChanged}>
                                    {locationOptions}
                                </Select>
                            ) : null}
                            <CTAButtons>
                                <Button onClick={this.go}>Go</Button>
                                {clearButtonVisible && <Button onClick={this.clearSearch}>Clear</Button>}
                            </CTAButtons>
                        </Form>
                        <List>
                            {openingsRender.length > 0 ? openingsRender : <Empty>No open positions found.</Empty>}
                        </List>
                    </Fragment>
                ) : (
                    <Empty>
                        There are currently no open positions, please check back in future for any job opportunities we
                        may have.
                    </Empty>
                )}
            </Body>
        )
    }
}
