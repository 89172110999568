import React, { Component, Fragment } from 'react'
import config from '../../../config'
import { Heading } from '../main-styles'
import { Body, CTAButtons, Button, Form, Input, Select, Empty } from './style'
import Accordion from './Accordion'
import { LoaderAllopenings } from './../../Loaders/index'

export default class Companies extends Component {
    constructor(props) {
        super(props)

        this.state = {
            search: '',
            location: 'all',
            openingsRender: [],
            locationOptions: {},
            locationObj: {},
            clearButtonVisible: false,
            companies: [],
        }
    }

    searchChanged = ({ target }) => {
        // first entered text cannot be <space>
        this.setState({
            search: target.value.length === 1 ? target.value.trim() : target.value,
        })
    }

    locationChanged = ({ target }) => {
        this.setState({
            location: target.value,
        })
    }

    applyNowLinkGenerator = id => {
        return `https://${config.config().env === 'production' ? '' : 'dev.'}springrecruit.com/applyNow/${id}`
    }

    go = e => {
        e.preventDefault()
        let { location, locationObj, search } = this.state
        let ls = []
        search = search.trim()

        if (location === 'all' && search.length > 0) {
            locationObj.all.forEach(opening => {
                if (opening.jobTitle.toLowerCase().indexOf(search.toLowerCase()) > -1) ls.push(opening)
            })
        } else if (location === 'all') {
            ls = locationObj.all
        } else if (search.length > 0) {
            locationObj[location].forEach(opening => {
                if (opening.jobTitle.toLowerCase().indexOf(search.toLowerCase()) > -1) ls.push(opening)
            })
        } else locationObj[location].forEach(opening => ls.push(opening))

        this.setState({
            openingsRender: ls,
            clearButtonVisible: true,
        })
    }

    clearSearch = e => {
        e.preventDefault()
        const { locationObj } = this.state
        let openingsRender = locationObj.all

        this.setState({
            openingsRender,
            location: 'all',
            search: '',
            clearButtonVisible: false,
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.companies &&
            prevProps.companies.length === 0 &&
            this.props.companies &&
            this.props.companies.length
        ) {
            let locationObj = {}
            const { companies } = this.props
            const allJobsOpening = []

            companies.forEach(({ jobs = [], companyName = '' }) => {
                if (companyName.length) {
                    jobs.forEach((opening, index) => {
                        const { jobLocation } = opening

                        // For location: all
                        allJobsOpening.push(opening)

                        if (opening.jobLocation && opening.jobLocation.length > 0) {
                            // this DataStructure will make title search for given location very quick
                            if (locationObj.hasOwnProperty(jobLocation)) locationObj[jobLocation].push(opening)
                            else locationObj[jobLocation] = [opening]
                        }
                    })
                }
            })

            const locationList = Object.keys(locationObj)

            // adding "all" location after keys as it will effect OptionList
            if (allJobsOpening.length) locationObj['all'] = allJobsOpening

            // the location can be null for all the jobs
            const locationOptions =
                (locationList.length &&
                    locationList.map(each => (
                        <option value={each} key={each}>
                            {each}
                        </option>
                    ))) ||
                []

            // explicitly adding "all locations" field
            if (locationList.length)
                locationOptions.unshift(
                    <option value="all" key="key-all-locations">
                        All Locations
                    </option>
                )

            this.setState({
                search: '',
                location: 'all',
                openingsRender: allJobsOpening,
                locationOptions,
                locationObj,
                clearButtonVisible: false,
                companies,
            })
        }
    }

    render() {
        const { search, location, locationOptions, openingsRender, clearButtonVisible, companies } = this.state
        const { isLoading } = this.props

        const companyRender =
            companies.map(({ companyName, _id }) => {
                return <Accordion data={openingsRender} companyId={_id} key={_id} companyName={companyName} />
            }) || []
        return (
            <Body id="all-openings">
                <Heading>All Openings</Heading>
                {isLoading ? (
                    <LoaderAllopenings />
                ) : (
                    <>
                        <Form>
                            <Input
                                placeholder="Search Job"
                                name="search"
                                value={search}
                                onChange={this.searchChanged}
                            />
                            {locationOptions.length ? (
                                <Select value={location} onChange={this.locationChanged}>
                                    {locationOptions}
                                </Select>
                            ) : null}
                            <CTAButtons>
                                <Button onClick={this.go}>Go</Button>
                                {clearButtonVisible && <Button onClick={this.clearSearch}>Clear</Button>}
                            </CTAButtons>
                        </Form>
                        {openingsRender.length ? companyRender : <Empty small>No open positions found.</Empty>}
                    </>
                )}
            </Body>
        )
    }
}
