import React, { Component, Fragment } from 'react'
import Nav from './Nav/Nav'
import Banner from './Banner/Banner'
import AllOpenings from './AllOpenings/AllOpenings'
import { getPageData } from '../../services/networkCalls'
import DynamicSection from './DynamicSection'
import { Helmet } from 'react-helmet'
import { customisedDomains } from '../../DataConfigs/customisedDomains'

import config from './../../config'
const { env, apiURL, redirectURL } = config.config()
/*
Default Props used also
*/

class Career extends Component {
    constructor(props) {
        super(props)
        const { data, hasData } = props

        if (hasData && data) {
            this.state = {
                nav: {
                    logo_url: data.settings && data.settings.company.logo_url,
                    companyName: data.settings && data.settings.company.companyName,
                },
                banner: data.settings && data.settings.banner,
                work: data.settings && data.settings.why_work_with_us,
                culture: data.settings && data.settings.culture,
                benefits: data.settings && data.settings.benefits,
                sections: data.settings && data.settings.sections,
                openings: [{ _id: 'empty-job-opening', jobs: [] }],
                hasSettings: data.settings !== null,
                isLoading: false,
            }
        } else {
            this.state = {
                culture: null,
                work: null,
                benefits: null,
                openings: [],
                nav: {}, // if image is absent then show companyName text
                banner: null,
                isLoading: true,
            }
            // document.title = "Careers";
        }
    }

    getRedirectUrl = () => {
        // redirect disabled for widgets
        return this.props.isApp ? redirectURL : ''
    }

    getCompanyDomain = () => {
        // in production url : <domain>.springrecruit.com
        // on dev: dev-widget.springrecruit.com/<domain
        let domain = null
        if (env === 'production') domain = (window.location.hostname && window.location.hostname.split('.')[0]) || ''
        else domain = window.location.pathname.replace('/', '')

        // changing the domain with respect to the hardcoded domains
        return customisedDomains[domain] || domain
    }

    windowRedirectHandler = url => {
        const { isApp } = this.props
        if (isApp) {
            window.location.href = url
            return true
        }
        return false
    }

    componentDidMount() {
        const { hasData, isApp } = this.props
        if (hasData) return null
        console.log("Greetings")
        const domain = this.getCompanyDomain()
        const params = window.location.pathname.toLowerCase()
        const redirectURL = this.getRedirectUrl()
        if (domain.length === 0) {
            this.windowRedirectHandler(redirectURL)
            return null
        }

        // in production url : <domain>.springrecruit.com/careers if no /careers redirect
        if (
            env === 'production' &&
            params.trim() != '/' &&
            params !== '/careers' &&
            this.windowRedirectHandler(redirectURL)
        ) {
            return null
        }

        getPageData(domain)
            .then(res => {
                if (res && res.error) {
                    this.windowRedirectHandler((res && res.redirect) || redirectURL)
                } else {
                    this.setState({
                        nav: {
                            logo_url: res.settings && res.settings.company.logo_url,
                            companyName: res.company && res.company.companyName,
                            companyUrl: res.company && res.company.websiteUrl,
                        },
                        banner: res.settings && res.settings.banner,
                        work: res.settings && res.settings.why_work_with_us,
                        culture: res.settings && res.settings.culture,
                        benefits: res.settings && res.settings.benefits,
                        sections: res.settings && res.settings.sections,
                        openings: res.jobOpenings,
                        hasSettings: res.settings !== null,
                        isLoading: false,
                    })
                    // document.title = `Careers | ${res.company && res.company.companyName}`
                }
            })
            .catch(err => {
                if (err && this.windowRedirectHandler(redirectURL)) {
                    // no need to log error as the page will redirect
                    // and
                    // the function evaluation will handle the redirect based on isApp prop
                } else {
                    // adding error log since it is not re-rerouted
                    console.error('CAREER-PAGE', err)
                }
            })
    }

    render() { 
        const { openings, nav, banner, isLoading } = this.state
        const { hasData } = this.props

        const resolvedCompanyName = nav.companyName? " at "+ nav.companyName : ""
        const META_TAG_DESC = `Join us if you are looking for an exciting career opportunity${resolvedCompanyName}. We are Hiring!`
        const META_TAG_TITLE = `Job Openings & Career Opportunities${resolvedCompanyName}`

        return (
            <Fragment>
                <Helmet>
                    <title>{META_TAG_TITLE}</title>
                    <meta name="description" content={META_TAG_DESC} />
                </Helmet>
                <Nav data={nav} isLoading={isLoading} />
                <Banner data={banner} isLoading={isLoading} />
                <DynamicSection data={this.state} />
                <AllOpenings data={openings} hasData={hasData} isLoading={isLoading} />
            </Fragment>
        )
    }
}
/*
 *
 * hasData : true  used for inflating the component with the internal data (without using the API)
 *       Needs: data prop if true
 *
 * isApp : true will enable URL re-routes if error occurs.
 *
 *
 */

Career.defaultProps = {
    hasData: false,
    isApp: true,
    data: null,
}

export default Career
