import React, { useState, useEffect } from 'react'
import Openings from './Openings'
import OpeningsPreview from './OpeningsPreview'
import Companies from './Companies'

const AllOpenings = ({ data, hasData, isLoading }) => {
    // No Openings are not shown in preview mode
    if (hasData) return <OpeningsPreview />

    if (data.length === 1) return <Openings key={data[0]._id} data={data[0].jobs} />

    return <Companies companies={data} isLoading={isLoading} />
}

export default AllOpenings
