import styled from 'styled-components'

export const Body = styled.section`
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
`
export const Num = styled.span`
    font-family: SFProHeavy;
    word-break: break-word;
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
`
export const Description = styled.div`
    font-size: 14px;
    color: #666;
`
