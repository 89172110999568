import React from 'react'
import { Body } from './style'
import { Heading, Description } from '../main-styles'

export default function Section({ data }) {
    return (
        <Body>
            <Heading>{data.title}</Heading>
            <Description>{data.description}</Description>
        </Body>
    )
}
