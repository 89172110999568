import styled from 'styled-components'
import ArrowIcon from '../../../../assets/img/up.svg'

export const Body = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f7f8fc;
`
export const Item = styled.div`
    width: 50%;
    padding: 0 20px;
    margin: 10px 0;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    @media (max-width: 767px) {
        width: 90%;
    }
`

export const Arrow = styled.img.attrs({ src: ArrowIcon })`
    position: absolute;
    right: 0;
    top: 35px;
    transform: rotate(-180deg);
    transition: transform 0.4s ease-out;
    ${props => props.isOpen && `transform: rotate(0deg);`}
`
export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
    cursor: pointer;
    position: relative;
    font-family: SFHeavy;
    font-size: 16px;
    color: #333;
    text-transform: uppercase;
    @media (max-width: 767px) {
        width: 90%;
    }
`
export const Content = styled.div`
    height: 0;
    opacity: 0;
    transform: translateY(-10px);

    ${({ isOpen }) =>
        isOpen
            ? 'transition: height 0.2s linear, opacity 0.2s linear 0.2s, transform 0.2s linear 0.2s;'
            : 'transition: height 0.2s linear 0.2s, opacity 0.2s linear, transform 0.2s linear;'}

    ${props =>
        props.isOpen &&
        `
            height: ${props.total * 80}px;
            opacity: 1;
            transform: translateY(0px);
            @media (max-width: 767px) {
                height: ${props.total * 100}px;
            }
        `}
`
export const Location = styled.span`
    display: flex;
    align-items: center;
    font-size: 12px;
    svg {
        margin-right: 4px;
    }
`
