const URL = {
    PROD: 'https://api.springrecruit.com/api/v2/career-page/',
    DEV: 'https://api-dev.springrecruit.com/api/v2/career-page/',
    LOCAL: 'http://localhost:8080/api/v2/career-page/',
}

const REDIRECTS = {
    PROD: 'https://springrecruit.com',
    DEV: 'https://dev.springrecruit.com',
}

export default {
    config: () => {
        switch (process.env.REACT_APP_ENV) {
            case 'production':
                return {
                    apiURL: URL.PROD,
                    redirectURL: REDIRECTS.PROD,
                    env: process.env.REACT_APP_ENV,
                }
            case 'dev':
                return {
                    apiURL: URL.DEV,
                    redirectURL: REDIRECTS.DEV,
                    env: process.env.REACT_APP_ENV,
                }
            case 'local':
                return {
                    apiURL: URL.LOCAL,
                    redirectURL: REDIRECTS.DEV,
                    env: process.env.REACT_APP_ENV,
                }
            default:
                return {
                    apiURL: URL.DEV,
                    redirectURL: REDIRECTS.DEV,
                    env: process.env.REACT_APP_ENV,
                }
        }
    },
}
